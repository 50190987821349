@import '~@/themes/variable.less';
@header-height: 84px;
@footer-height: 460px;
// mobile
@mobile-header-height: 60px;

.header-container,
.main-container,
.footer-container {
  width: 100%;
}

.main-container {
  min-height: calc(100vh - @header-height - @footer-height);
  background: #ffffff;
  &.mobile {
    min-height: calc(100vh - @mobile-header-height - @footer-height);
    margin-top: @mobile-header-height;
  }
}

@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;