@import '~@/themes/font.less';
@import '~@/themes/variable.less';
.product-header {
   &.header {
        height: 464px;
   } 
    .banner {
        margin-top: 124px;
        display: flex;
        &__left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 55%;
            &__title {
                font-weight: 600;
                font-size: 44px;
                line-height: 40px;
                color: #fff;
              
            }
            &__subTitle {
                font-weight: 400;
                font-size: 16px;
                line-height: 32px;
                color: #fff;
                margin: 24px 0;
            }
            &__button {
                background-color: @primary-color;
                display: flex;
                align-items: center;
                width: 112px;
                height: 48px;
                border-radius: 4px;
                padding: 16px;
                border: none;
                color: #fff;
                font-weight: 600;
                font-size: 14px;
                line-height: 16px;
                svg {
                    margin-right: 4px;
                }
            }
        }
        &__right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 45%;
            img {
                width: 400px;
                height: 280px;
            }
        }
    }
}
@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;