.f-j-c {
  display: flex;
  justify-content: center;
}
.font24 {
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
}
.font32 {
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
}
.font14 {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
}
.font16 {
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
