@import '~@/themes/font.less';
@import '~@/themes/variable.less';
.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(#000000, 0.6);
  z-index: 999;
  display: flex;
  justify-content: flex-end;
  overflow-x: hidden;
  .sidebar {
    width: 100vw;
    height: 100%;
    background: #ffffff;
    // padding: 20px 35px;
    .sidebar-header {
      padding: 10px 35px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgba(232, 254, 251, 0.95);
    }
    .sidebar-close {
      width: 24px;
      height: 24px;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .sidebar-body {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background: #ffffff;
      .header-menu {
        border-right: 0;
        .ant-menu-submenu {
          .ant-menu-submenu-title {
            font-weight: bold;
            font-size: 18px;
            justify-content: center;
            .ant-menu-title-content {
              flex: unset;
            }
            .ant-menu-submenu-arrow {
              // display: none;
              right: calc(50vw - 70px);
              color: @primary-color;
            }
          }
          .ant-menu-item {
            font-size: 18px;
            text-align: center;
            a {
              color: rgba(@primary-color, 0.7);
            }
          }
          .ant-menu-sub.ant-menu-inline {
            background: #e4f0ef;
          }
        }
        & > .ant-menu-item {
          .ant-menu-title-content {
            font-weight: bold;
            font-size: 18px;
            text-align: center;
          }
        }

        .ant-menu-item,
        .ant-menu-submenu-title {
          padding: 24px !important;
          height: auto;
          margin: 0;
          font-size: 18px;
          color: rgba(@primary-color, 0.7);
          border-bottom: 0.5px solid #e3e7ed;
          a {
            color: rgba(@primary-color, 0.7);
          }
        }
        .ant-menu-item:active,
        .ant-menu-submenu-title:active {
          background: @primary-color;
          color: #ffffff;
          a {
            color: #ffffff;
          }
        }
      }
      .header-app-btn {
        width: 200px;
        margin: 21px 0;
        text-align: center;
        font-size: 1.142857rem /* 16/14 */;
        padding: 12px 23px;
        padding-top: 12px !important;
      }
    }
  }
}

@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;