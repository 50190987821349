@import '~@/themes/variable.less';
.customer-cases {
    .f-j-c;
    &__container {
        padding: 48px 0;
        width: @container-width;
        display: flex;
        &__item {
            flex: 1;
            &:first-of-type {
                margin-right: 48px;
            }
            &-title {
                font-weight: 600;
                font-size: 44px;
                line-height: 40px;
                margin-bottom: 60px;
                &-sub {
                    margin-left: 32px;
                    color:@text-light;
                    font-weight: 300;
                    text-transform: uppercase;
                }
            }
            &-content {
                padding: 48px;
                background: #FFFFFF;
                border: 1px solid #DAE2F2;
                &-subTitle {
                    .font24;
                    color:@primary-color;
                    margin-bottom: 16px;
                }
                &-title {
                    .font32;
                    color: @text-color;
                }
                &-other {
                    .font16;
                    color: @text-light;
                }
            }
        }

    }
}
@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;