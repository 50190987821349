@import '~@/themes/variable.less';
@import '~@/themes/font.less';
* {
  box-sizing: border-box;
  &:before {
    box-sizing: border-box;
  }
  &:after {
    box-sizing: border-box;
  }
}

html,
body {
  font-family: Helvetica Neue, Arial, sans-serif;
  font-size: 14px;
}

ul {
  list-style-type: none;
  margin: 0;
}

html {
  &.mobile {
    font-size: 12px;
  }
}

@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;