@import '~@/themes/variable.less';
.about-us-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 40px;
    display: flex;
    align-items: center;
    margin: 20px 0;
    color: white;
    
    svg {
        margin-right: 12px;
    }
}
.about-us-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    padding: 24px;
    opacity: 1;
    background: #001A4C;
    color:white;
    
    &-wrap {
        padding: 1px;
        background: linear-gradient(180deg, #3377FF 0%, rgba(51, 119, 255, 0) 100%);
    }
}

.about-us {
    .f-j-c;
    background: linear-gradient(180deg, #002B80 0%, #001A4C 100%);
    width: 100%;
    &__container {
        padding-top: 60px;
        padding: 80px;
        width: @container-width;

        &__title {
        font-weight: 600;
        font-size: 44px;
        line-height: 40px;
        color: #ffffff;
        margin-bottom: 60px;
        
            &-sub {
                font-weight: 300;
                color: @text-light;
                text-transform: uppercase;
                
            }
        }

        &__top {
            display: flex;
            align-items: center;
            &-left {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .font16;
                color:#ffffff;
                &-bottom {
                    &-title{
                        .about-us-title;
                    }
                    &-content{
                        .about-us-content;
                    }
                }
            }
            &-right {
                margin-left: 20px;
                width: 376px
            }
        }
        &__bottom {
            display: flex;
            margin-top: 30px;
            &-item {
                flex:1;
                &:first-of-type {
                    margin-right: 30px;
                }
                &-title {
                    .about-us-title
                }
                &-content {
                    .about-us-content
                }
            }
        }

    }
}
@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;