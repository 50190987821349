@import '~@/themes/variable.less';
.product-section {
    width: 100%;
    padding-top: 40px;
    .product-tabs {
        margin-top: -40px;
        width: 100%;
        .f-j-c;
        border-bottom: 1px solid #DAE2F2;
    
        &-inner {
            display: flex;
            width: @container-width;
        }
    
        &-tab {
            height: 80px;
            border-bottom: 2px solid transparent;
            margin-right: 24px;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;
    
            &.active {
                border-color: @primary-color;
                color: @primary-color;
            }
        }
    }
    
    .product-content {
        .f-j-c;
        width: 100%;
    
        &.background-grey {
            background: #F7F9FC;
        }
    
        &-container {
            padding: 80px 0;
            width: @container-width;
    
            &-content {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

    
                &__left {
                    width: 55%;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 32px;
    
                    text-align: justify;
                }

                .feature-item {
                    display: flex;
                    align-items: center;
                    width: 32%;
                    margin: 10px 0;

                    &__left {
                        width: 48px;
                        height: 48px;
                        background : rgba(255, 0, 0, 0.2);
                        margin-right: 12px;
                    }
                    &__right {
                        flex: 1;
                     
                        
                        &-title {
                            display: flex;
                            align-items: center;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 24px;
                            margin-bottom: 8px;
                            word-wrap: break-word;
                            word-break: break-all;
                            
                        }
                        &-subtitle {
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                }

                .scenario {
                    &-tab {
                        width: 210px;
                        height: 72px;
                        display: flex;
                        align-items: center;
                        text-align: justify;
                        color: #334466;
                        cursor: pointer;
                        justify-content: center;
                        border-right: 1px solid #DAE2F2;
                        transition: background-color 700ms;

                        &:not(:last-of-type) {
                            border-bottom: 1px solid #DAE2F2;
                        }

                       &:hover, &.active {
                            background: #0D5DFF;
                            color: #ffffff;
                        }
                    }
                    &-desc {
                        padding-left: 30px;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 32px;
                        color: #7A8599;
                        text-align: justify;
                    }
                }

                .other-item {
                    border: 1px solid #DAE2F2;
                    box-sizing: border-box;
                    background: #FFFFFF;
                    box-shadow: 0px 20px 40px rgba(51, 68, 102, 0.05);
                    border-radius: 8px;
                    &-title {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        padding: 32px;
                        color: #334466;
                    }
                }
            }
        }
    
        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 40px;
            color: #334466;
            display: flex;
            align-items: center;
            margin-bottom: 40px;
    
            &-sub {
                margin-left: 8px;
            }
    
            svg {
                margin-right: 8px;
            }
    
        }
    
    }
}

@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;