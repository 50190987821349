@import '~@/themes/font.less';
@import '~@/themes/variable.less';
@header-height: 848px;
@header-menu-height: 84px;
// mobile
@mobile-header-height: 600px;
@mobile-header-menu-height: 60px;

.header{
  background-image: url('../../../assets/images/banner_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  backdrop-filter: blur(15px);
  height: @header-height;
  padding: 12px 71px;
  display: flex;
  justify-content: center;

  &.short {
    height: @header-menu-height;
  }

  .header-container {
    width: 1280px;
     
    &__menu {
      position: fixed;
      width: 100%;
      z-index: 999;
      height: @header-menu-height;
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 1280px;
      .header-main{
        width: 100%;
      }
      
      &.mobile {
        height: @mobile-header-menu-height;
      }
  
    }
    &__carousel {
     margin-top: @header-menu-height;
     padding: 40px 0;
      height: 764px;

      .slide {
        &__container {
          display: flex;
        }
        &__left {
          flex:1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          &__title{
            font-weight: 400;
            font-size: 72px;
            line-height: 56px;
            color: white;
            &__sub {
              font-weight: 400;
              font-size: 72px;
              line-height: 56px;
              background: linear-gradient(92.56deg, #80C3FE 0%, #266EFF 100%);
              background-clip: text;
              -webkit-text-fill-color: transparent;
              margin-left: 12px;
            }
          }
          &__desc {
            font-weight: 400;
            font-size: 24px;
            line-height: 24px;
            color: white;
            margin: 30px 0;
          }
          &__subtitle {
            font-weight: 600;
            font-size: 44px;
            line-height: 40px;
            color: white;
            margin-bottom: 60px;
          }
          &__button {
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;
            width: 153px;
            height: 72px;
            background: @primary-color;
            border-radius: 8px;
            display: flex;
            color: white;
            justify-content: center;
            align-items: center;
            text-align: center;
            border: none;
          }
        }
        &__right {
          padding: 40px 0;
          img {
            width: 540px;
          }
        }
      }

      .slick-dots {
        li {
          width: 32px;
          height: 8px;
        }

      }
    }


  
    .logo {
      img {
        height: 100%;
      }
    }
  
    .header-main {
      display: flex;
      justify-content: flex-end;
      align-items: center;
  
      .header-menu {
        flex: 1;
        justify-content: flex-end;
        align-items: center;
  
        &,
        a {
          color: white;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
  
          &:hover,
          &:active,
          &:visited {
            color: @primary-color;
          }
        }
  
        .ant-menu-title-content {
          font-size: 16px;
        }
  
        .ant-menu-submenu {
          .ant-menu-submenu-title {
            .ant-menu-title-content {
              display: flex;
              align-items: center;
  
              .anticon {
                margin-left: 0.809286rem /* 11.33/14 */;
              }
            }
          }
        }
      }
    }
  
    .ant-menu-horizontal {
      border-bottom: 0;
      // .ant-menu-submenu-horizontal {
      //   .ant-menu-submenu-arrow {
      //     display: block;
      //   }
      // }
    }
  
    .header-app-btn {
      font-weight: 900;
      font-size: 16px;
      line-height: 100%;
      display: flex;
      padding-top: 14px;
      padding-bottom: 14px;
      align-items: center;
      text-transform: uppercase;
      margin-left: 2.785714rem /* 39/14 */;
  
      svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
  
      &.mobile {
        margin-left: 0;
        font-size: 12px;
      }
    }
  
    .header-mobile-menu-btn {
      border-radius: 4px;
      margin-left: 1.428571rem /* 20/14 */;
      padding-left: 0;
      padding-right: 0;
      border: 0;
      background: transparent;
  
      svg {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }
    }
  
    &.mobile {
      backdrop-filter: none;
      padding: 10px 27px;
  
      .logo {
        height: @mobile-header-height - 10px * 2;
      }
    }
  
    &.tablet {
      .sidebar-container {
        height: 100vh;
  
        .sidebar {
          width: 40% !important;
          display: flex;
          flex-direction: column;
  
          .sidebar-body {
            flex: 1;
            justify-content: space-between;
  
            .ant-menu-title-content {
              font-weight: 600;
              font-size: 24px;
              line-height: 24px;
            }
  
            .header-menu .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow {
              right: calc(20vw - 90px);
            }
          }
        }
  
      }
    }
  }
}

.header-menu-popup {
  background: @primary-color;
  box-sizing: border-box;

  .ant-menu {
    .ant-menu-item {
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: flex-end;
      text-align: right;
      text-transform: uppercase;
      height: auto;
      color: white;

      &,
      a {
        color: @primary-color;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
      }

      padding: 14px 34px;
      margin: 0;
      min-width: 12.714286rem /* 178/14 */;
      border-bottom: 1px solid #e3e7ed;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background: @primary-color;
        color: #ffffff;

        a {
          color: #ffffff;
        }
      }
    }
  }
}

.tablet {
  .header-container {
    padding: 12px 30px;
  }
}

@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;