@import '~@/themes/variable.less';

.product {
    .f-j-c;

    &-container {
        padding-top: 40px;
        width: @container-width;
        &__title {
                display: flex;
                justify-content: space-between;
                margin-bottom: 64px;

                &-left {
                    font-weight: 600;
                    font-size: 44px;
                    line-height: 40px;

                    &-sub {
                        font-weight: 300;
                        color: @text-light;
                        text-transform: uppercase;
                    }
                }
            }
        &__top {
            display: flex;
            justify-content: space-between;

            &-item {
                flex: 1;
                padding: 48px;
                height: 447px;
                position: relative;
                cursor: pointer;
                border: 1px solid transparent;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                &:hover {
                    background: #FFFFFF;
                    border: 1px solid @border-light;

                    .active-border {
                        display: block;
                    }
                }

                &.transport {
                    .product-container__top-item__top {
                        border-bottom: 1px solid @border-light;
                        padding-bottom: 48px;
                    }
                }

                &__top {
                    &-title {
                        .font32;
                        color: @text-color;
                    }

                    &-link {
                        .font16;
                        display: flex;
                        margin-top: 16px;
                        align-items: center;
                    }
                }

                &__bottom {
                    display: flex;
                    flex-direction: column;
                    &-product {
                        .font14;
                        margin: 16px 0;
                        color: #7A8599;

                        &:hover {
                            text-decoration: underline;
                            color: @primary-color;
                        }
                    }
                }


                .active-border {
                    background: @primary-color;
                    display: none;
                    position: absolute;
                    border-radius: 4px;
                    width: 64px;
                    margin-top: -48px;
                    height: 8px
                }

                &-title {
                    .font32;
                    color: text-color;
                }

                &-desc {
                    color: text-light;
                    .font14;
                    margin: 16px 0;
                    line-height: 24px;
                    font-weight: 400;
                }
            }
        }
    }

}
@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;