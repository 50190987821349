@import '~@/themes/font.less';
@import '~@/themes/variable.less';
.case-header {
   &.header {
        height: 280px;
   } 
    .banner {
        display: flex;
        &__left {
            display: flex;
            width: 45%;
            align-items: center;
            &__title {
                font-weight: 600;
                font-size: 44px;
                line-height: 40px;
                color: #fff;
                margin-right: 40px;
              
            }
            &__subTitle {
                font-weight: 600;
                font-size: 44px;
                line-height: 40px;
                color: #3377FF;
                text-transform: uppercase;
            }
        }
        &__right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 55%;
            img {
                width: 400px;
                height: 280px;
            }
        }
    }
}
@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;