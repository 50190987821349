@import '~@/themes/font.less';
@import '~@/themes/variable.less';

.contact-info {
  background-image: url('../../../assets/images/bg_contact.jpeg');

  width: 100%;
  .f-j-c;
  &-container {
     width: @container-width;
    display: flex;
    padding: 60px;
  }

  &__item {
   
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 80px;
    &:not(:last-of-type) {
      flex:1;
      margin-right: 16px;
    }
    &-top {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      color:white;
      .f-j-c;
      align-items: center;
      margin-bottom:16px;
    }
    &-bottom {
      font-weight: 700;
      font-size: 32px;
      line-height: 40px;
      color:#ffffff;
    }

    &.card {
      flex-direction: row;
      align-items: stretch;
      background: #FFFFFF;
      border-radius: 8px;
      width: 320px;
      padding: 20px;
    }
    &-right {
      .font14;
      color:@text-light;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-left {
     margin-right: 16px;
    }

  }
}

.footer {
  .f-j-c;
  background: #0B1426;
  .footer-container {
    padding: 60px;
    width: @container-width;
    .footer-body {
      display: flex;
      align-items: baseline;
  
      .footer-columns {
        flex: 1;
        display: flex;
        align-items: flex-start;
  
        .footer-column {
          flex:1;
          &.middle {
            flex:4;
            display: flex;
            flex-basis: 160px;
          }

          &-item {
            display: flex;
            flex-direction: column;
            margin-left: 48px;
            justify-content: flex-start;
            &-header{
              font-weight: 600;
              font-size: 18px;
              line-height: 24px;
              color: @text-light;
              margin-bottom: 16px;
            }
            &-item {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color:#ffffff;
              margin: 10px 0;
            }
            a:hover {
              color: @primary-color;
            }
          }
          
          &:last-of-type {
            display: flex;
            justify-content: flex-end;
          }
          .public-number {
            width: 96px;
            &__text{
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color:#ffffff;
                background: @primary-color;
                height: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
          }
  
        }
      }
    }
  
    .footer-bottom {
      text-align: center;
      padding-top: 32px;
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      border-top: 1px solid #334466;
  
      .left {
        .font14;
        line-height: 24px;
        color: @text-light;
        margin-right: 16px;
      } 
  
     .company {
        color:white;
      } 
       
      .right {
          .font14;
          line-height: 24px;
          color:@text-light;
      }  
    
    }
  }
}

@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;