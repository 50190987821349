@import '~@/themes/variable.less';

.case-section {
    width: 100%;
    padding-top: 40px;
    .case-tabs {
        margin-top: -40px;
        width: 100%;
        .f-j-c;
        border-bottom: 1px solid #DAE2F2;

        &-inner {
            display: flex;
            width: @container-width;
        }

        &-tab {
            height: 80px;
            border-bottom: 2px solid transparent;
            margin-right: 24px;
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            align-items: center;

            &.active {
                border-color: @primary-color;
                color: @primary-color;
            }
        }
    }

    .case-content {
        .f-j-c;
        width: 100%;

        &.background-grey {
            background: #F7F9FC;
        }

        &-container {
            padding: 80px 0;
            width: @container-width;

            &-content {
                display: flex;
                justify-content: space-between;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 32px;
                text-align: justify;
                   

                &__left {
                    border-right: 1px solid #DAE2F2;
                }
                &__right {
                    text-align: justify;
                    padding-left: 20px;
                    img {
                        width: 100%;
                        margin-bottom: 50px;
                    }

                }

                
                .case-presentation-tab {
                    padding: 8px;
                    text-align: center;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 24px;
                    .f-j-c;
                    align-items: center;
                    width: 228px;
                    height: 72px;
                    cursor: pointer;
                    border-bottom: 1px solid #DAE2F2;
                    &.active, &:hover {
                        background: #0D5DFF;
                        color: #FFFFFF;
                    }
                }
           
                .other-item {
                    border: 1px solid #DAE2F2;
                    box-sizing: border-box;
                    background: #FFFFFF;
                    box-shadow: 0px 20px 40px rgba(51, 68, 102, 0.05);
                    border-radius: 8px;

                    &-title {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                        padding: 32px;
                        color: #334466;
                    }
                }
            }
        }

        &__title {
            font-weight: 600;
            font-size: 28px;
            line-height: 40px;
            color: #334466;
            display: flex;
            align-items: center;
            margin-bottom: 40px;

            &-sub {
                margin-left: 8px;
            }

            svg {
                margin-right: 8px;
            }

        }

    }
}
@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;