.main-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .main-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .main-bg-repeat {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    svg {
      width: 1440px;
    }
  }
  .main-body {
    display: flex;
    justify-content: center;
    // z-index: 10;
    width: 100%;
  }

  .main-body-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex: 1;
    background: #ffffff;
  }

  &.mobile {
    .main-body {
      margin-top: 1rem;
    }
  }
}

@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;