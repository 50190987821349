// colors
@primary-color: #0D5DFF;
@text-color:#334466;
@text-light: #7A8599;

// border
@border-light: #DAE2F2;


// container
@page-max-width: 1280px;
@page-padding: 24px;
@container-max-width: @page-max-width - @page-padding * 2;

@container-width:1280px;
.f-j-c {
    display: flex;
    justify-content: center;

}

.font24 {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
}

.font32 {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
}

.font14 {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
}
.font16 {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
}