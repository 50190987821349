@import '~@/themes/variable.less';

.media {
    .f-j-c;
    width: 100%;
    background: linear-gradient(180deg, #F2F6FF 0%, #DAE2F2 100%);
    &-container {
        padding-top: 60px;
        padding-bottom: 80px;
        width: @container-width;
        &__title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 64px;
            &-left {
                font-weight: 600;
                font-size: 44px;
                line-height: 40px;
                &-sub {
                    font-weight: 300;
                    color:@text-light;
                    text-transform: uppercase;
                }
            }
        }
        &__layer {
            display: flex;
            :first-of-type {
                margin-bottom: 40px;
            }
            &-item {
                flex: 1;
                display: flex;
                padding: 40px;
                height: 170px;
                position: relative;
                background: #F2F6FF;
                border: 1px solid #DAE2F2;
                box-sizing: border-box;
                border-radius: 8px;

                &:hover {
                    background: #ffffff;
                    .active-border {
                        display: block;
                    }
                }

                &:first-of-type {
                    margin-right: 48px;
                }
                
                .active-border {
                    width: 8px;
                    height: 24px;
                    position: absolute;
                    display: none;
                    top: 40px;
                    left: 0px;
                    border-radius: 8px;
                    background: @primary-color;
                }

                &-left {
                    margin-right: 20px;
                }
                &-right{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    &-top {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 24px;
                    }
                    &-bottom {
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: @text-light;
                        display:flex;
                        justify-content: space-between;
                        align-items: center;
                        svg {
                            path {
                                stroke: @text-light;
                            }
                        }
                        &:hover{
                            color: @primary-color;
                            path {
                                stroke: @primary-color;
                            }
                        }
                    }
                }
            }
        }
    }
}
@primary-color: #0D5DFF;@layout-header-height: 32px;@menu-bg: transparent;